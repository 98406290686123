// dependencies.
import React from 'react'
import Img from 'gatsby-image'
// components.
import PageHead from 'src/components/head/PageHead'
import Heading from 'src/components/heading/Heading'
import { Header, Footer } from 'src/components/layout'
import DownloadSection from 'src/components/pages/shared/DownloadSection'
// utils.
import { chunkArray } from 'src/utils'
import { useLatestVersion } from 'js/utils/hooks/useLatestVersion'
import { useRedditPixel, useMetaPixel, useTikTokPixel } from 'src/components/head/Analytics'
import { useRellax } from 'src/js/utils/hooks'
import buildImageObject from 'src/js/utils/buildImgObject'
import logEvent from 'src/js/analytics'
import processCampaigns from 'src/js/campaigns'
// constants.
import { DOWNLOADS_URL } from 'src/constants'
import { DOWNLOAD_MACOS_MS_PAGE } from 'src/js/analytics/trackers'
// data.
import M1Data from 'src/data/pages/Contents/m1.json'

// Styles & Images
import 'static/m1/scss/styles.scss'

const images = {
  desktopApp: {
    aspectRatio: 1.3595,
    path: '/m1/img/',
    fileType: 'png',
    files: [
      {
        fileName: 'desktop-app-ssize',
        width: 762,
      },
      {
        fileName: 'desktop-app-msize',
        width: 1524,
      },
      {
        fileName: 'desktop-app-lsize',
        width: 2284,
      },
    ],
  },
}

const iconSVG = (num, title) => {
  switch (num) {
    case 0:
      return (
        <svg
          width="93"
          height="47"
          viewBox="0 0 93 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            d="M19.0938 0.34375V46H11.5625V9.28125L0.40625 13.0625V6.84375L18.1875 0.34375H19.0938ZM35.8125 24.8438L29.8125 23.375L32.2812 0.5H56.8125V6.875H38.5312L37.2812 18.0312C37.9896 17.6146 38.9688 17.2083 40.2188 16.8125C41.4688 16.3958 42.8958 16.1875 44.5 16.1875C46.6458 16.1875 48.5625 16.5417 50.25 17.25C51.9583 17.9375 53.4062 18.9479 54.5938 20.2812C55.7812 21.5938 56.6875 23.1979 57.3125 25.0938C57.9375 26.9688 58.25 29.0833 58.25 31.4375C58.25 33.5417 57.9375 35.5104 57.3125 37.3438C56.7083 39.1771 55.7917 40.7917 54.5625 42.1875C53.3333 43.5833 51.7812 44.6771 49.9062 45.4688C48.0521 46.2396 45.8542 46.625 43.3125 46.625C41.4167 46.625 39.5938 46.3542 37.8438 45.8125C36.1146 45.25 34.5625 44.4271 33.1875 43.3438C31.8125 42.2396 30.7083 40.8854 29.875 39.2812C29.0417 37.6562 28.5625 35.7917 28.4375 33.6875H35.8125C36 35.1667 36.4062 36.4271 37.0312 37.4688C37.6771 38.4896 38.5312 39.2708 39.5938 39.8125C40.6562 40.3542 41.8854 40.625 43.2812 40.625C44.5521 40.625 45.6458 40.4062 46.5625 39.9688C47.4792 39.5104 48.2396 38.8646 48.8438 38.0312C49.4688 37.1771 49.9271 36.1771 50.2188 35.0312C50.5312 33.8854 50.6875 32.6146 50.6875 31.2188C50.6875 29.8854 50.5104 28.6667 50.1562 27.5625C49.8229 26.4583 49.3125 25.5 48.625 24.6875C47.9583 23.875 47.1146 23.25 46.0938 22.8125C45.0729 22.3542 43.8958 22.125 42.5625 22.125C40.7708 22.125 39.3958 22.3854 38.4375 22.9062C37.5 23.4271 36.625 24.0729 35.8125 24.8438ZM67.7812 39.6562L63.2188 35.125L87.5625 10.2812L92.125 14.8438L67.7812 39.6562ZM87.625 39.6562L63.2812 14.8438L67.8438 10.2812L92.1875 35.125L87.625 39.6562Z"
            fill={`url(#paint${num}_01)`}
          />
          <defs>
            <linearGradient
              id={`paint${num}_01`}
              x1="-5"
              y1="28.2804"
              x2="95.0001"
              y2="28.2804"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5C5E69" />
              <stop offset="1" stop-color="#E9E9E9" />
            </linearGradient>
          </defs>
        </svg>
      )
    case 1:
      return (
        <svg
          width="104"
          height="46"
          viewBox="0 0 104 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0505 0.910782C29.0505 3.18762 28.2103 5.3135 26.5354 7.2812C24.5143 9.6204 22.0696 10.9721 19.4185 10.7588C19.3848 10.4857 19.3652 10.1982 19.3652 9.89608C19.3652 7.71031 20.3263 5.37111 22.0333 3.4585C22.8854 2.49011 23.9692 1.6849 25.2836 1.04258C26.595 0.409838 27.8356 0.0599132 29.0023 0C29.0363 0.304371 29.0505 0.608771 29.0505 0.910754V0.910782ZM37.925 35.4789C37.2295 37.0694 36.4063 38.5336 35.4525 39.8796C34.1524 41.7147 33.0879 42.9849 32.2675 43.6903C30.9957 44.848 29.6332 45.441 28.1741 45.4747C27.1266 45.4747 25.8634 45.1796 24.3929 44.5811C22.9177 43.9853 21.5619 43.6903 20.3222 43.6903C19.0221 43.6903 17.6277 43.9853 16.1363 44.5811C14.6426 45.1796 13.4392 45.4916 12.5192 45.5225C11.12 45.5815 9.72538 44.9717 8.33328 43.6903C7.44477 42.9231 6.33342 41.6079 5.00207 39.7447C3.57364 37.7551 2.39927 35.448 1.47925 32.8176C0.493933 29.9765 0 27.2254 0 24.5619C0 21.5108 0.665958 18.8794 1.99986 16.6742C3.04819 14.903 4.44284 13.5057 6.18836 12.48C7.93387 11.4543 9.81991 10.9316 11.851 10.8982C12.9623 10.8982 14.4197 11.2385 16.2308 11.9073C18.0368 12.5784 19.1964 12.9187 19.7048 12.9187C20.0849 12.9187 21.3731 12.5208 23.5569 11.7275C25.6221 10.9917 27.365 10.6871 28.7929 10.8071C32.662 11.1162 35.5689 12.6262 37.502 15.3464C34.0417 17.422 32.3299 20.3292 32.364 24.0586C32.3952 26.9634 33.4597 29.3808 35.5518 31.3001C36.5 32.191 37.5588 32.8795 38.7369 33.3684C38.4814 34.1019 38.2117 34.8044 37.925 35.4789ZM82.544 11.883V46H76.184V22.9716H75.9949L66.7268 45.669H62.2346L52.9665 22.9716H52.7773V46H46.4173V11.883H54.6924L64.3861 36.0699H64.5752L74.2689 11.883H82.544ZM103.539 46H96.4698V18.4321H96.3279L87.6273 24.4375V18.0066L96.4461 11.883H103.539V46Z"
            fill={`url(#paint${num}_02)`}
          />
          <defs>
            <linearGradient
              id={`paint${num}_02`}
              x1="-4.10964e-07"
              y1="25.2375"
              x2="103.539"
              y2="25.2375"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5C5E69" />
              <stop offset="1" stopColor="#E9E9E9" />
            </linearGradient>
          </defs>
        </svg>
      )
    default:
      return (
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>{title}</title>
          <path
            d="M49.7004 13.9607L28.4081 0V7.80553L42.0673 16.6815L40.4604 21.7663H28.4081V28.2337H40.4604L42.0673 33.3185L28.4081 42.1945V50L49.7004 36.0838L46.2187 25.0223L49.7004 13.9607Z"
            fill="url(#paint0_linear_519_8732)"
          />
          <path
            d="M9.88334 28.2337H21.891V21.7663H9.8387L8.27637 16.6815L21.891 7.80553V0L0.598633 13.9607L4.0804 25.0223L0.598633 36.0838L21.9356 50V42.1945L8.27637 33.3185L9.88334 28.2337Z"
            fill="url(#paint1_linear_519_8732)"
          />
          <mask
            id="mask0_519_8732"
            style={{ 'mask-type': 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="50"
            height="50"
          >
            <path
              d="M49.7004 13.9607L28.4081 0V7.80553L42.0673 16.6815L40.4604 21.7663H28.4081V28.2337H40.4604L42.0673 33.3185L28.4081 42.1945V50L49.7004 36.0839L46.2187 25.0223L49.7004 13.9607Z"
              fill="url(#paint2_linear_519_8732)"
            />
            <path
              d="M9.88334 28.2337H21.891V21.7663H9.8387L8.27637 16.6815L21.891 7.80553V0L0.598633 13.9607L4.0804 25.0223L0.598633 36.0839L21.9356 50V42.1945L8.27637 33.3185L9.88334 28.2337Z"
              fill="url(#paint3_linear_519_8732)"
            />
          </mask>
          <g mask="url(#mask0_519_8732)">
            <rect x="0.625" width="48.75" height="50" fill="url(#paint4_linear_519_8732)" />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_519_8732"
              x1="0.598633"
              y1="27.432"
              x2="49.7005"
              y2="27.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5C5E69" />
              <stop offset="1" stopColor="#E9E9E9" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_519_8732"
              x1="0.598633"
              y1="27.432"
              x2="49.7005"
              y2="27.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5C5E69" />
              <stop offset="1" stopColor="#E9E9E9" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_519_8732"
              x1="0.598633"
              y1="27.432"
              x2="49.7005"
              y2="27.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5C5E69" />
              <stop offset="1" stop-color="#E9E9E9" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_519_8732"
              x1="0.598633"
              y1="27.432"
              x2="49.7005"
              y2="27.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5C5E69" />
              <stop offset="1" stop-color="#E9E9E9" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_519_8732"
              x1="0.625"
              y1="27.432"
              x2="49.375"
              y2="27.432"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5C5E69" />
              <stop offset="1" stopColor="#E9E9E9" />
            </linearGradient>
          </defs>
        </svg>
      )
  }
}

// Helpers:
const getLink = (LatestReleaseData) => {
  const PREFIX = DOWNLOADS_URL
  const VERSION = LatestReleaseData.latest

  return `${PREFIX}/exodus-macos-arm64-${VERSION}.dmg`
}

// Partials:
const HeaderSection = ({ data }) => {
  useRellax({ className: 'm1-rellax', center: false })

  const handleDownloadLinkClick = () => {
    processCampaigns({ eventType: 'download' })
    logEvent(DOWNLOAD_MACOS_MS_PAGE(), 'MsPage')
    rdt('track', 'AddToCart') // eslint-disable-line no-undef
  }

  /* initialize Pixels */
  useRedditPixel()
  useMetaPixel()
  useTikTokPixel()

  const LatestReleaseData = useLatestVersion()
  return (
    <header className="x-header">
      <div className="x-header__content">
        <div className="x-header__content-container x__width">
          <i className="x-header__icon m1-rellax" data-rellax-speed="-4" />

          <div className="x-header__content-headings m1-rellax" data-rellax-speed="-2">
            <Heading page="m1" className="x-header__heading" />

            {data.description && <p className="x-header__description">{data.description}</p>}
            {data.downloadLinks && (
              <div className="x-header__download-buttons">
                {data.downloadLinks.map((link, l) => {
                  return (
                    <a
                      key={l}
                      href={getLink(LatestReleaseData)}
                      className={`x__button x__button--${link.name}`}
                      onClick={handleDownloadLinkClick}
                    >
                      <span className="x__button-align">
                        <span className="x__button-align-text">{link.copy}</span>
                      </span>
                    </a>
                  )
                })}
              </div>
            )}

            <div className="x-header__links">
              By downloading Exodus, you agree to the{' '}
              <a href="/terms/" target="_blank">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="/privacy/" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="x-header__wallet m1-rellax" data-rellax-speed="-5" role="presentation">
        <Img
          fluid={buildImageObject(images.desktopApp)}
          durationFadeIn={1000}
          alt={data.imageAlt}
        />
      </div>
    </header>
  )
}

const FeaturesItem = ({ index, svg, description, headerText }) => (
  <li className="col-12 col-md x-features__feature-item x-features__feature-item">
    <div className={`x-features__feature-item__card x-features__feature-item__card--${index + 1}`}>
      {headerText && (
        <span
          className="x-features__feature-item__header-text"
          dangerouslySetInnerHTML={{ __html: headerText }}
        />
      )}
      <figure className="x-features__feature-item__icon">{svg}</figure>
      <span dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </li>
)

const FeaturesSection = ({ data }) => {
  const itemsPerRow = 3
  const rows = chunkArray(data, itemsPerRow)

  return (
    <section className="x-features">
      <div className="x-features__content">
        <div className="row">
          <div className="col-12">
            {rows.map((row, r) => {
              return (
                <ul key={`row--${r}`} className="row x-features__content-container">
                  {row.map((item, i) => {
                    const originalItemIndex = i + r * itemsPerRow
                    return (
                      <FeaturesItem
                        key={`item--${originalItemIndex}`}
                        index={originalItemIndex}
                        svg={iconSVG(originalItemIndex, item.title)}
                        description={item.description}
                        headerText={item.headerText}
                      />
                    )
                  })}
                </ul>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const DisclaimerSection = ({ data }) => (
  <section className="x-disclaimer">
    {data.description && (
      <div className="x-disclaimer__content">
        <div className="x-disclaimer__content-container x__width">
          <p className="x-disclaimer__description">{data.description}</p>
        </div>
      </div>
    )}
  </section>
)

// <head> component:
export function Head() {
  return <PageHead page="m1" />
}

// Main Component:
const M1Page = () => (
  <div className="x-page-m1">
    <Header background="black" />

    <main className="x">
      <HeaderSection data={M1Data.header} />
      <FeaturesSection data={M1Data.features} />
      <DisclaimerSection data={M1Data.disclaimer} />
      <DownloadSection
        page="m1"
        copy={M1Data.download.copy}
        path={M1Data.download.path}
        background="black"
      />

      <Footer black />
    </main>
  </div>
)

export default M1Page
